








































































































































































































































































.btnStyle {
  display: flex;
  justify-content: center;
}
.courseWare {
  display: flex;
  align-items: center;
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
}
