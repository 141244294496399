.upload .uploadvideo {
  display: flex;
}
.upload .uploadvideo .el-upload {
  height: auto !important;
  border: none;
}
.upload .el-button {
  height: auto !important;
}
