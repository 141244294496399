
























































































































































































































































































































































































.upload {
  .uploadvideo {
    display: flex;
    .el-upload {
      height: auto !important;
      border: none;
    }
  }
  .el-button {
    height: auto !important;
  }
}
